.explore-button {
  background-color: $color--yellow;
  position: absolute;
  left: 0;
  top: 0;
  color: $color--purple;
  transform-origin: left top;
  letter-spacing: 0.2em;
  transform: rotate(-90deg) translateX(-180%);
  z-index: 2;
  &:hover {
    transform: rotate(-90deg) translateX(-180%);
  }
}
.methodology-timeline-wrapper {
  position: absolute;
  //width: 12px;
  height: 100vh;
  right: 15px;
  top: 0;
  bottom: 0;
  z-index: 2;
  @media (min-width: 992px) {
    right: 25px;
  }
  .spacer {
    height: 15vh;
  }
  .methodology-timeline {
    //position: absolute;
    //right: 0;
    //top: 15vh;
    //bottom: 15vh;
    position: relative;
    margin: auto;
    width: 12px;
    height: 70vh;
    //right: 25px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &:before {
      content: "";
      display: block;
      width: 2px;
      margin: auto;
      height: 100%;
      background-color: #d8d8d8;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
    }
    .timeline-progress {
      position: absolute;
      width: 2px;
      height: 0;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      background-color: $color--pink;
      pointer-events: none;
    }
    .timeline-dot {
      position: absolute;
      display: block;
      left: 0;
      right: 0;
      margin: auto;
      top: 0;
      width: 12px;
      height: 12px;
      background-color: $color--pink;
      border-radius: 50%;
      pointer-events: none;
      &:after {
        content: "";
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        display: block;
        background-color: $color--purple;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
      }
    }
    a {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #d8d8d8;
      display: block;
      //transition: all 0.3s ease-in-out;
      &:nth-of-type(1),
      &.active-step {
        background-color: $color--pink;
      }
    }
  }
}
.methodology-scene {
  .explore-button {
    background-color: $color--yellow;
    position: absolute;
    left: 0;
    top: 0;
    color: $color--purple;
    transform-origin: left top;
    transform: rotate(-90deg) translateX(-180%);
    &:hover {
      transform: rotate(-90deg) translateX(-180%);
    }
  }
  .scroll-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .section-content {
    min-height: 100vh;
    position: relative;
    .scroll-indicator {
      position: absolute;
      bottom: 1rem;
      left: 0;
      right: 0;
      margin: auto;
      img {
        animation: blink 1.2s ease-out infinite forwards;
      }
    }
  }
  .section-divider {
    width: 100%;
    height: 20vh;
    //background-color: $color--pink;
    //margin: auto;
    //transform: scaleY(0);
    //transform-origin: top center;
    position: relative;
    & > span {
      position: absolute;
      width: 1px;
      height: 100%;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      transform: scaleY(0);
      transform-origin: top center;
      background-color: $color--pink;
    }
  }
  .home-video-wrapper {
    opacity: 0.45;
    @media (min-width: 992px) {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      overflow: hidden;
      padding-bottom: 56.25%;
    }
    &,
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
    img {
      object-fit: cover;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &:after {
      content: "";
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgb(9, 9, 26);
      background: linear-gradient(
        180deg,
        rgba(9, 9, 26, 0) 0%,
        rgba(9, 9, 26, 1) 100%
      );
    }
  }
}

.section-1,
.section-2,
.section-4,
.section-5,
.section-6 {
  svg {
    width: 100%;
    height: auto;
  }
}
.section-3 {
  overflow: hidden;
  @media (min-width: 992px) {
    svg {
      position: absolute;
      width: 120%;
      height: auto;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }
}

.section-6 svg {
  position: relative;
  [id$="Button"] {
    // position: absolute;
    // top: 0;
    // left: 0;
    // right: 0;
    // margin: auto;
  }
}
