.share-buttons-wrapper {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  @media (min-width: 992px) {
    width: 41.67%;
    flex: 0 0 41.67%;
    max-width: 41.67%;
  }
  .share-buttons {
    ul {
      width: 36.42%;
      flex: 0 0 36.42%;
      max-width: 36.42%;
      margin: 0;
      padding: 0;
      li {
        width: 17.34%;
        position: relative;
        margin-right: 10.4%;
        &:after {
          content: "";
          display: block;
          padding-top: 100%;
        }
        a {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: $color--pink;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $color--purple;
          font-size: 0.8em;
        }
      }
    }
    .share-text {
      color: #fff;
      &:before {
        content: "";
        height: 1px;
        width: 32px;
        background-color: #fff;
        display: block;
        margin-right: 0.5em;
      }
    }
  }
}
