.service-landing {
    .regular {
      font-size: 1.2rem;
    }
  
    .weird-title {
      margin-top: 5rem;
  
      @media screen and (min-height: 1100px) {
        margin-top: 0;
      }
    }
  
    &__slider p.lead {
      font-family: 'SilkaMedium';
      font-size: 25px;
    }
  
    @media screen and (max-width: 600px) {
      &__slider--slide {
        padding: 2rem !important;
      }
    }
  
    @media screen and (min-width: 950px) {
      &__banner--container {
        min-height: 1050px;
      }
    }
  
    @media screen and (max-height: 1050px) {
      &__banner--container {
        padding-top: 4rem;
      }
    }
  
    @media screen and (min-height: 1050px) {
      &__banner.header {
        height: 1050px;
      }
    }
  
    .h2.semibold {
      font-family: 'SilkaSemibold';
    }
  
    .service-tabs {
      position: relative;
    }
  
    .tab-inputs {
      position: sticky;
      top: 0;
      z-index: 1;
      background: #09091a;
      padding-top: 42px;
    }
  
    .service-tabs .tab-inputs > input[type="radio"] {
      position: absolute;
      left: -200vw;
    }
  
    .service-tabs .tab-panel {
      display: none;
    }
  
    .tab-panels > .tab-panel:first-child {
      display: block;
    }
  
    .service-tabs .tab-inputs > label {
      position: relative;
      display: inline-block;
      padding: 15px 15px 25px;
      border: 1px solid transparent;
      border-bottom: 0;
      cursor: pointer;
      font-weight: 600;
      width: 20%;
      text-align: center;
      margin: auto;
      font-size: 1rem;
  
      @media screen and (min-width: 700px) {
        font-size: 25px;
      }
    }
  
    @media screen and (max-width: 499px) {
      .service-tabs .tab-inputs > label {
        width: 94%;
      }
    }
  
    @media screen and (max-width: 990px) {
      .service-tabs .tab-inputs > label {
        width: 45%;
      }
    }
  
    .service-tabs .tab-inputs > label::after {
      content: "";
      position: absolute;
      left: 15px;
      bottom: 10px;
      width: 90%;
      height: 4px;
      background: #8d8d8d;
    }
  
    .service-tabs .tab-inputs > label:hover,
    .service-tabs .tab-inputs > input:focus + label {
      color: #3b3fb6;
    }
  
    .service-tabs .tab-inputs > label:hover::after,
    .service-tabs .tab-inputs > input:focus + label::after,
    .service-tabs .tab-inputs > input:checked + label::after {
      background: #3b3fb6;
    }
  
    .service-tabs .tab-inputs > input:checked + label {
      margin-bottom: -1px;
    }
  
    .service-tabs .tab-panel {
      padding: 30px 0;
    }
  
    .service-tabs hr {
      border-top: solid 1px #363648;
    }
  
    .bullets-servicio ul {
      list-style: none;
    }
  
    .bullets-servicio li {
      position: relative;
      padding-left: 40px;
      margin-bottom: 10px;
    }
  
    .bullets-servicio li:before {
      content: "";
      position: absolute;
      top: 18px;
      left: 0;
      transform: translateY(-50%);
      height: 30px;
      width: 30px;
      background: url('/img/servicios/bullet.svg') no-repeat;
      background-size: contain;
    }
  
    .bullets-servicio li span {
      display: block;
      margin-left: 10px;
      font-size: 1.3rem;
      margin-top: 1rem;
    }
  
    .cta-larger {
      font-size: large !important;
      margin-left: 3rem;
    }
  
    .tab-inputs label {
      font-family: SilkaBold;
    }
  
    .customer-logo {
      max-width: 140px;
    }
  
    .line-12 {
      line-height: 1.2;
      font-family: 'SilkaRegular';
      font-size: 1.25rem;
    }
  }
  