 .posts-banner {
  .posts-banner__controls {
    button {
      background-color: $color--lightpurple-2;
      color: #fff;
      border: none;
      padding: 0.8rem 2rem;
      transition: all 0.3s ease-in-out;
      &:hover {
        background-color: $color--lightpurple;
      }
    }
  }
  .posts-banner__slider {
    background-color: #fff;
    .slick-track {
      display: flex;
      .slick-slide {
        height: inherit;
        & > div {
          line-height: 0;
          height: 100%;
        }
      }
    }
    .posts-banner__slide {
      position: relative;
      .posts-banner__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2;
        opacity: 0;
        transition: all 0.3s ease-in-out;
        pointer-events: none;
      }
      .overlay-icon {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        color: #fff;
        font-size: 1.8rem;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
        z-index: 2;
        transition: all 0.3s ease-in-out;
        & > div {
          border: solid 2px #fff;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .slide-img {
        background-color: lightgray;
        position: relative;
        &:after {
          content: "";
          display: block;
          padding-top: 112%;
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
        }
        .posts-banner__overlay {
          mix-blend-mode: multiply;
        }
      }
      .slide-title__content {
        z-index: 2;
        transition: all 0.3s ease-in-out;
      }
      &:hover {
        .posts-banner__overlay,
        .overlay-icon {
          opacity: 1;
        }
        .slide-title__content {
          color: #fff;
        }
      }
    }
  }
  .more-posts {
    //background-color: $color--yellow;
    background: rgb(255, 181, 73);
    background: linear-gradient(
      90deg,
      rgba(255, 181, 73, 0.67) 0%,
      rgba(255, 181, 73, 1) 49%
    );
    position: relative;
    @media (min-width: 992px) {
      padding-left: calc(150px + 3rem) !important;
    }
    @media (min-width: 992px) {
      &:before {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 2px;
        width: 150px;
        background-color: #fff;
      }
    }
  }
  .posts-banner__title {
    background-color: #fff;
  }
}

body.blog {
  .posts-banner {
    .posts-banner__slider,
    .posts-banner__title {
      background-color: #e4e4e4;
    }
    .more-posts {
      background-color: $color--lightpurple-2;
    }
  }
}
