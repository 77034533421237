.newsletter-banner {
  @media (max-width: 768px) {
    background-image: linear-gradient(to bottom, #09091a 2%, #f0b001);
  }


  content-visibility: auto;

  h2 {
    @media (max-width: 768px) {
      font-size: 1.6rem;
      font-family: 'SilkaSemibold';
    }
  }

  .newsBanner--conoce {
    font-size: 1rem;
    font-family: 'SilkaRegular';
  }

  .privacy-label {
    @media (max-width: 768px) {
      display: none;
    }

    a {
      color: $color--yellow;
    }
  }

  .newsletter-form-wrapper {
    padding-top: 50px;
    padding-bottom: 50px;

    @media (max-width: 768px) {
      padding-top: 15px;
      padding-bottom: 15px;
    }

    &>svg {
      display: none;

      @media (min-width: 992px) {
        display: block;
        position: absolute;
        right: 0;
        top: -30px;
        width: 80%;
        height: auto;
      }
    }

    .emailContainer {
      button {
        display: none;
      }

      @media (max-width: 768px) {
        position: relative;

        #emailInput {
          background-color: rgb(255, 255, 255);
          padding: 0 40px 0 22px;
          height: 37px;
          position: relative;
        }

        button {
          cursor: pointer;
          display: inline-block;
          height: 37px;
          position: absolute;
          top: 0px;
          width: 37px;
          right: 0px;
          background-color: rgb(255, 255, 255);
          border: 0;

      
        svg {
          width: 12px;
          height: auto;
          transition: opacity 0.3s ease-in-out;
        }

        .fa-chevron-right {
          &.hide {
            opacity: 0;
          }
        }

        .fa-spin {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          opacity: 0;
          width: 40%;

          &.show {
            opacity: 1;
          }
        }

  
        }













      }
    }
  }

  .newsletter-bg {
    position: absolute;
    background-color: $color--yellow;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    margin: auto;

    @media (min-width: 992px) {
      width: 80%;
    }

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/img/newsletter-bg.jpg");
      background-size: cover;
      background-position: center;
      opacity: 0.1;
      mix-blend-mode: multiply;

      @media (max-width: 768px) {
        background-image: none;
        background-color: #e9a541;
      }
    }
  }

  .newsletter-form {
    background-color: #fff;
    width: calc(100% - 30px);
    margin-left: 15px;
    color: #5a5a5a;

    label[for="emailInput"] {
      color: #9e9e9e;
      position: absolute;
      top: 0.5rem;
      left: 0;
      pointer-events: none;
      transition: all 0.3s ease-in-out;
      transform-origin: left center;
      font-size: 1rem;

      @media (min-width: 768px) {
        font-size: 1.5rem;
      }
    }

    #emailInput {

      &:focus,
      &.not-empty {
        &+label {
          top: -1.2rem;
          transform: scale(0.7);
        }
      }
    }

    .newsletter-message {
      opacity: 0;
      position: absolute;
      bottom: 0;
      left: 15px;

      @media (min-width: 992px) {
        left: 50px;
      }
      @media (max-width: 768px) {
        position: relative;
        height: inherit;
        margin: 0;
        left: 22px;
        font-size: 0.9rem;
        top: 4px;
      }
    }

    @media (min-width: 992px) {
      width: calc(100% - 100px);
      margin-left: 40px;
    }

    input {
      width: 100%;
      border: none;
      border-bottom: solid 2px $color--lightpurple-2;
      padding-bottom: 10px;

      @media (min-width: 992px) {
        font-size: 1.3rem;
        padding-bottom: 0.3rem;
        font-weight: 300;
        width: 70%;
      }

      @media (max-width: 768px) {
        border-bottom: 0;
      }
    }

    ::placeholder {
      color: #9e9e9e;
      line-height: 1rem;
      font-family: "Kanit", sans-serif;
      font-weight: 200;
    }

    .frequency-option {
      font-size: 80%;

      &:before {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #d5d5d5;
        margin-right: 5px;
      }
    }

    input[type="radio"] {
      display: none;

      &:checked+label {
        &:before {
          border: solid 3px #d5d5d5;
          background-color: $color--lightpurple-2;
        }
      }
    }

    .button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translateY(50%);

      @media (min-width: 992px) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: translateX(50%);
      }

      button {
        background-color: $color--pink;
        color: #fff;
        width: 45px;
        height: 45px;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        transition: all 0.3s ease-in-out;
        position: relative;

        @media (min-width: 992px) {
          width: 90px;
          height: 90px;
        }

  
    

        svg {
          width: 25px;
          height: auto;
          transition: opacity 0.3s ease-in-out;

          @media (min-width: 992px) {
            width: 40px;
          }
        }

        .fa-envelope {
          &.hide {
            opacity: 0;
          }
        }

        .fa-spin {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
          opacity: 0;
          width: 40%;

          &.show {
            opacity: 1;
          }
        }

        &:hover {
          transform: scale(0.9);
        }
      }
    }
  }
}
