$color--purple: #09091a;
$color--lightpurple: #21145f;
$color--lightpurple-2: #3b3fb6;
$color--lightpurple-3: #3759f0;
$color--lightpurple-4: #00a9e0;
$color--yellow: #ffb548;
$color--yellow2: #ffb223;
$color--mustard: #cc890c;
$color--pink: #ff6d70;
$color--blue: #252363;
$color--pink-secondary: #d50057;
$color--pink-tertiary: #ff6359;
$color--red: #8a1538;
$color--stone: #c5b9ad;
