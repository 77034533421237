.services-header {
  .header-wrapper {
    img {
      height: 80vh;
      object-fit: cover;
      object-position: center;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgb(0, 0, 0);
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(9, 9, 26, 1) 100%
      );
    }
  }
}

.services-wrapper {
  margin-top: -6.5rem;
  position: relative;
}

.services-navigation {
  .nav-tabs {
    border-bottom: none;
    justify-content: center;
    .nav-item {
      z-index: 0;
      &.active {
        z-index: 2;
      }
    }
    .nav-link {
      border-radius: 0;
      color: rgba(59, 63, 182, 0.45);
      background-color: $color--purple;
      border: solid 2px rgba(59, 63, 182, 0.45);
      margin-left: -2px;
      font-size: 1rem;
      @media (min-width: 768px) {
        font-size: 1.8rem;
      }
      &.active {
        color: $color--lightpurple-2;
        border-color: $color--lightpurple-2;
      }
    }
  }
}

#services-content {
  //margin-top: -20%;
  @media (min-width: 992px) {
    margin-top: 0;
  }
  .service-title {
    @media (min-width: 992px) {
      font-size: 2.8rem;
    }
  }
  .service-description {
    @media (min-width: 992px) {
      font-size: 1.2rem;
    }
  }
}

.service-content-list {
  li {
    &:before {
      content: "*";
      display: inline-block;
      color: $color--yellow;
      margin-right: 0.5rem;
    }
  }
}

.services-cta {
  &:after {
    content: "";
    display: block;
    width: 1px;
    height: 200px;
    margin: auto;
    margin-top: 3rem;
    background-color: $color--lightpurple-2;
  }
}

.cta-banner-secondary {
  padding: 10vh 0;
  background-image: url("/img/bi-icon-big.png");
  background-repeat: no-repeat;
  background-size: 50%;
  background-size: contain;
}

