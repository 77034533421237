.blog {
  .featured-posts {
    .subscribe-box {
      position: absolute;
      width: 29.12%;
      z-index: 2;
      bottom: 0;
      left: 0;
      .subscribe-box__title {
        background-color: $color--yellow;
        color: $color--purple;
      }
      .subscribe-box__content {
        background-color: $color--lightpurple;
        position: relative;
        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-image: url("/img/subscribe-box-bg.jpg");
          opacity: 0.15;
        }
        p {
          z-index: 2;
        }
      }
    }
    .featured-posts__images {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      @media (min-width: 992px) {
        width: 84.65%;
        flex: 0 0 84.65%;
        max-width: 84.65%;
      }
      .slick-slide {
        line-height: 0;
      }
      .slick-dots {
        position: absolute;
        list-style: none;
        margin: 0;
        padding: 0;
        bottom: 22px;
        left: 0;
        display: flex;
        margin-left: 38.23%;
        li {
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background-color: #d5d5d5;
          opacity: 0.3;
          margin-right: 14px;
          cursor: pointer;
          &.slick-active {
            opacity: 1;
          }
          button {
            display: none;
          }
        }
      }
      .featured-posts__img {
        width: 100%;
        &:after {
          content: "";
          display: block;
          padding-top: 57.3%;
          background-color: #000;
          opacity: 0.5;
        }
        img {
          object-fit: cover;
          top: 0;
          left: 0;
          z-index: -1;
        }
        .featured-posts__img-title {
          position: absolute;
          width: 70%;
          top: 0;
          right: 0;
          height: 100%;
          z-index: 2;
          padding-right: 6.5%;
          line-height: 1.5;
          @media (min-width: 992px) {
            width: 52.23%;
            height: 77.6%;
          }
          h2 {
            font-size: 1rem;
            @media (min-width: 768px) {
              font-size: 2rem;
            }
          }
        }
      }
    }
    .featured-posts__text {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
      @media (min-width: 992px) {
        width: 29.21%;
        flex: 0 0 29.21%;
        max-width: 29.21%;
        transform: translateY(-38.2%);
      }
      .slick-slide {
        line-height: 0;
      }
      a {
        background-color: $color--lightpurple;
        display: block;
        width: 100%;
        position: relative;
        &:after {
          content: "";
          display: block;
          padding-top: 100%;
          background-image: url("/img/featured-posts-controls.jpg");
          opacity: 0.15;
        }
        .featured-posts__text-content {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          padding: 0 19.56%;
          padding-top: 16.77%;
          line-height: 1.5;
          .featured-post-title {
            &:after {
              content: "";
              display: block;
              width: 57%;
              height: 3px;
              background-color: $color--pink;
              margin-top: 21px;
            }
          }
        }
      }
    }
    .featured-posts__text-controls {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translateX(-100%);
      width: 19.56%;
      height: 61.8%;
      .slider-indicator {
        left: 0;
        right: auto;
        top: 0;
        bottom: auto;
        width: 100%;
        height: 100%;
        position: relative;
        padding: 0;
        .slider-indicator__bar {
          height: 67.46%;
          &:before {
            height: 100%;
          }
          span {
            height: 33.3333333%;
          }
        }
      }
    }
  }
}

// .blog {
//   .featured-posts {
//     position: relative;
//     .share-buttons {
//       width: 36.5%;
//       li {
//         margin-right: 15px;
//         a {
//           display: block;
//           width: 30px;
//           height: 30px;
//           background-color: $color--pink;
//           color: $color--purple;
//           display: flex;
//           align-items: center;
//           justify-content: center;
//         }
//       }
//     }
//     .subscribe-box {
//       position: absolute;
//       width: 41.5%;
//       z-index: 2;
//       bottom: 0;
//       left: 0;
//       transform: translateX(-50%);
//       .subscribe-box__title {
//         background-color: $color--yellow;
//         color: $color--purple;
//       }
//       .subscribe-box__content {
//         background-color: $color--lightpurple;
//         position: relative;
//         &:after {
//           content: "";
//           position: absolute;
//           top: 0;
//           left: 0;
//           width: 100%;
//           height: 100%;
//           background-image: url("/img/subscribe-box-bg.jpg");
//           opacity: 0.15;
//         }
//         p {
//           z-index: 2;
//         }
//       }
//     }
//     .featured-posts__images {
//       .featured-posts__img {
//         line-height: initial;
//         &:after {
//           content: "";
//           display: block;
//           padding-top: 100%;
//           background-color: #000;
//           opacity: 0.5;
//           @media (min-width: 992px) {
//             padding-top: 57%;
//           }
//         }
//         img {
//           top: 0;
//           left: 0;
//           object-fit: cover;
//         }
//         .animated-btn {
//           font-size: 1.3rem;
//         }
//       }
//       .featured-posts__img-title {
//         top: 0;
//         height: 79.5%;
//         right: 0;
//         display: flex;
//         flex-direction: column;
//         justify-content: center;
//         z-index: 2;
//         // top: 0;
//         // bottom: 0;
//         // right: 0;
//         // margin: auto;
//         // z-index: 2;
//         // display: flex;
//         // flex-direction: column;
//         // justify-content: center;

//         h2 {
//           font-size: 1.5rem;
//           @media (min-width: 768px) {
//             font-size: 2rem;
//           }
//         }
//         @media (min-width: 768px) {
//           width: 50%;
//           font-size: 2rem;
//         }
//       }
//       .slick-slide {
//         line-height: 0;
//       }
//       .slick-dots {
//         position: absolute;
//         bottom: 10px;
//         left: 40%;
//         list-style: none;
//         margin: 0;
//         padding: 0;
//         display: flex;
//         li {
//           width: 8px;
//           height: 8px;
//           border-radius: 50%;
//           background-color: #d5d5d5;
//           opacity: 0.3;
//           cursor: pointer;
//           margin: 0 5px;
//           &.slick-active {
//             opacity: 1;
//           }
//           button {
//             display: none;
//           }
//         }
//       }
//     }
//     .featured-posts__controls {
//       background-color: $color--lightpurple;
//       @media (min-width: 992px) {
//         transform: translateY(-30%);
//       }
//       &:after {
//         content: "";
//         display: block;
//         padding-top: 100%;
//         background-image: url("/img/featured-posts-controls.jpg");
//         background-size: cover;
//         background-position: center;
//         opacity: 0.15;
//       }
//     }
//     .featured-posts__content-wrapper {
//       top: 0;
//       left: 0;
//       right: 0;
//       bottom: 0;
//       margin: auto;
//       z-index: 2;
//       display: flex;
//       align-items: center;
//       & > div {
//         max-width: 100%;
//       }
//     }
//   }
//   .featured-post-title {
//     &:after {
//       content: "";
//       display: block;
//       width: 60%;
//       height: 3px;
//       background-color: $color--pink;
//       margin-top: 1.8rem;
//     }
//   }
//   .featured-post-date,
//   .featured-post-author {
//     color: #d5d5d5;
//   }
//   .featured-posts-next-btn {
//     position: absolute;
//     background-color: $color--pink;
//     color: $color--purple;
//     width: 50px;
//     height: 50px;
//     bottom: 0;
//     right: 0;
//     border: none;
//     z-index: 2;
//   }
//   .slider-indicator {
//     background-color: $color--yellow;
//     right: auto;
//     left: 0;
//     top: auto;
//     bottom: 0;
//     height: 70%;
//     transform: translateX(-100%);
//     .slider-indicator__bar {
//       &:before,
//       span {
//         background-color: #292a2b;
//       }
//       span {
//         height: 33%;
//       }
//     }
//     .slider-indicator__number {
//       color: #292a2b;
//     }
//   }
// }

// .post-card {
//   .post-card__img {
//     position: relative;
//     &:after {
//       content: "";
//       display: block;
//       padding-top: 85%;
//       background-color: #000;
//       opacity: 0.6;
//       @media (min-width: 768px) {
//         padding-top: 52%;
//       }
//     }
//     img {
//       position: absolute;
//       width: 100%;
//       height: 100%;
//       top: 0;
//       left: 0;
//       object-fit: cover;
//     }
//   }
//   .post-card__content {
//     position: absolute;
//     width: 100%;
//     height: 100%;
//     top: 0;
//     right: 0;
//     z-index: 2;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     h2 {
//       font-size: 1.3rem;
//       @media (min-width: 768px) {
//         font-size: 2rem;
//       }
//     }
//     @media (min-width: 768px) {
//       width: 70%;
//     }
//   }
//   .post-card__meta {
//     background-color: #d5d5d5;
//     color: #292a2b;
//   }
// }

// .posts-list {
//   .col-lg-6 {
//     .post-card {
//       .post-card__img {
//         &:after {
//           padding-top: 85%;
//           @media (min-width: 768px) {
//             padding-top: 65%;
//           }
//         }
//       }
//       .post-card__content {
//         h2 {
//           font-size: 1.3rem;
//         }
//       }
//     }
//   }
// }

// .sidebar-cat {
//   background-size: cover;
//   background-position: center;
//   background-repeat: no-repeat;
//   position: relative;
//   display: flex;
//   align-items: center;
//   &:after {
//     content: "";
//     display: block;
//     padding-top: 100%;
//   }
//   & > div {
//     position: absolute;
//     width: 85%;
//     left: 0;
//     right: 0;
//     margin: auto;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     background-color: $color--yellow;
//     @media (min-width: 992px) {
//       width: 55%;
//     }
//     &:after {
//       content: "";
//       display: block;
//       padding-top: 100%;
//     }
//   }
//   h3 {
//     font-size: 0.7rem;
//     @media (min-width: 768px) {
//       font-size: 1rem;
//     }
//   }
// }

// .posts-pagination {
//   .posts-pagination__numbers {
//     background-color: $color--lightpurple-2;
//   }
//   .posts-pagination__buttons {
//     a {
//       background-color: $color--pink;
//       display: flex;
//       align-items: center;
//       justify-content: center;
//       width: 100%;
//       height: 100%;
//       border: none;
//       color: #fff;
//       &:hover {
//         background-color: #fb6053;
//       }
//     }
//   }
// }
