@import "~bootstrap/scss/bootstrap.scss";
@import "~slick-carousel/slick/slick.scss";
@import "~slick-carousel/slick/slick-theme.scss";
@import "~select2/src/scss/core.scss";
@import "~animate.css/animate.css";

$hamburger-padding-x: 0;
$hamburger-padding-y: 0;
$hamburger-layer-width: 30px;
$hamburger-layer-height: 2px;
$hamburger-layer-color: #fff;
$hamburger-types: (slider);
@import "~hamburgers/_sass/hamburgers/hamburgers";
@import "variables";
@import "base";
@import "components/components";
@import "pages/pages";