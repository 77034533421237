.cta-banner {
    content-visibility: auto;
  .cta-banner__content {
    background-color: #fff;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url("/img/cta-bg.jpg");
      background-size: cover;
      background-position: center;
      opacity: 0.1;
    }
  }
  .cta-banner__quote {
    background-color: $color--red;
    &:after {
      content: "";
      display: block;
      padding-top: 50%;
    }
    & > div {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
    }
    h3 {
      font-size: 2rem;
    }
    .tbi-btn {
      font-size: 80%;
      background-color: #fff;
      color: $color--purple;
    }
  }

  .reach-of-services {
    background-color: #c3c3c3;
    position: relative;
    width: 100%;
    &:after {
      content: "";
      display: block;
      padding-top: 60%;
    }
    @media (min-width: 992px) {
      width: 50%;
      &:after {
        padding-top: 100%;
      }
    }
    & > div {
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
    }
    .tbi-btn {
      font-size: 80%;
    }
  }
  .cta-video-img {
    iframe {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 0;
    }
  }
}
