.capabilities-bg {
  top: 0;
  left: 0;
  object-fit: cover;
  z-index: 2;
  pointer-events: none;
}

.services-reach {
  .nav-tabs {
    border-bottom: none;
    .nav-link {
      border: none;
      border-radius: 0;
      color: #fff;
      &.active,
      &:hover {
        background-color: transparent;
        color: $color--lightpurple-2;
        border-radius: 0;
        border: none;
      }
    }
  }
  .reach__btn {
    display: none;
    opacity: 0;
  }
}

.team-structure {
  background-color: $color--lightpurple;
  .team-structure__trigger {
    display: none;
    @media (min-width: 992px) {
      display: block;
      font-size: 6rem;
      color: #12093e;
    }
  }
  .team-structure__btn {
    display: none;
    @media (min-width: 992px) {
      display: block;
    }
  }
  .team-structure__content {
    @media (min-width: 992px) {
      display: none;
      opacity: 0;
    }
  }
}

#capabilities-video {
  iframe {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}
