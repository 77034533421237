$font-menu-size: 14px;
$font-menu-size-md: 12px;
$font-menu-size-mobile: 16px;

svg[hidden] {
  display: none;
  position: absolute;
}

.hamburger {
  z-index: 5;
}

.site-navigation {
  width: 100%;

  @media (max-width: 991px) {
    background-image: linear-gradient(152deg, #09091a 25%, #3b3fb6 100%);
    height: 120vh;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    transition: transform 0.3s ease-in-out;
    position: absolute;
    opacity: 0;
    transform: translateX(-100%);

    &.show-menu {
      transition: transform 0.3s ease-in-out;
      transform: translate3d(0, 0, 0);
      opacity: 1;
    }

    .clicky-menu {
      margin-top: 100px;
      > li {
        justify-content: center;
        margin-bottom: 1.2rem;
        > a {
          font-size: $font-menu-size-mobile;
          justify-content: center;
          &.btn-yellow {
            justify-content: center;
            width: 100%;
            background-color: transparent;
            color: #ffb223;
          }
        }
        > button {
          font-size: $font-menu-size-mobile;
          justify-content: center;
        }

        ul {
          li {
            a {
              text-align: center;
              font-size: 16px;
              padding-top: 0.5rem;
              padding-bottom: 0.5rem;
            }
          }
        }
      }
    }

    .menu_social {
      a {
        margin: 0 10px;
        background-color: #161333;
        color: #fff;
        width: 50px;
        height: 50px;
        svg {
          margin: 10px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
}

.siteSwitcher{
    flex-grow: 0.2 !important;
    img{
        width: 30px;
        &.active{
            border: 2px solid #ffb223;
            border-radius: 50%;
        }
    }
}
.clicky-menu {
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
  a {
    transition: color 0.3s ease-out;
    &:hover {
      background: transparent;
      color: #ffb223;
    }
    background-color: transparent;
    text-transform: uppercase;
    text-decoration: none;
    font-family: "SilkaSemibold";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    font-size: 12px;

    &.btn-yellow {
      background-color: #ffb223;
      color: #000;
      max-width: max-content;
      padding: 0.5rem 2rem;
      margin: auto;
    }
  }
  button {
    transition: color 0.3s ease-out;
    background-color: transparent;
    text-transform: uppercase;
    object-fit: contain;
    text-decoration: none;
    font-family: "SilkaSemibold";
    font-weight: 600;
    font-size: 12px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    &:hover {
      background: transparent;
      color: #ffb223;
    }
  }
  > li {
    position: relative;
    flex: 1 1 auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    > a {
      flex: 1 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border: 0;
      font-size: inherit;
      line-height: 1.5;
      cursor: pointer;
      font-size: $font-menu-size;
      @media (max-width: 1200px) and (min-width: 991px) {
        font-size: $font-menu-size-md;
      }
    }
    > button {
      flex: 1 0 auto;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      border: 0;
      font-size: inherit;
      line-height: 1.5;
      cursor: pointer;
      font-size: $font-menu-size;
      @media (max-width: 1200px) and (min-width: 991px) {
        font-size: $font-menu-size-md;
      }
    }
  }

  svg {
    width: 1em;
    height: 1em;
    margin-left: 0.5em;
  }

  [aria-expanded="true"] {
    svg {
      transform: scaleY(-1);
    }
  }

  ul {
    transition: opacity 0.3s ease-out;
    background-color: #10102a;
    min-width: 100%;
    width: 12em;
    margin-top: 19px;
    padding: 0;
    list-style: none;
    opacity: 0;

    a {
      display: block;
      padding-top: 0.375em;
      padding-bottom: 0.375em;
    }

    li {
      a {
        text-align: left;
        text-transform: none;
        position: relative;
        &:before {
          content: "";
          display: inline-block;
          position: relative;
          left: 0;
          top: -4px;
          bottom: 0;
          margin: auto;
          width: 0px;
          height: 0px;
          margin-right: 10px;
          transition: all 0.3s ease-in-out;
          background-color: #47475a;
        }
        &:hover {
          &:before {
            width: 22px;
            height: 1px;
          }
        }
      }
      border-bottom: solid 0.3px #3b3fb6;
      &:last-child {
        border-bottom: solid 2px #00a9e0;
      }
    }
  }
  ul[aria-hidden="false"] {
    opacity: 1;
    position: static;
    width: 100%;
    flex: 0 0 auto;
  }
}

@media (min-width: 991px) {
  .clicky-menu {
    display: flex;
    > li {
      > a {
        justify-content: center;
      }
      > button {
        justify-content: center;
      }
    }
    ul[aria-hidden="false"] {
      position: absolute;
      width: auto;
    }
    ul {
      a {
        padding: 0.375em 1em;
        white-space: nowrap;
      }
    }
  }
}
