@font-face {
  font-family: "SilkaRegular";
  src: local("SilkaRegular"),
    url("/fonts/silka-regular-webfont.eot") format("embedded-opentype"),
    url("/fonts/silka-regular-webfont.woff") format("woff"),
    url("/fonts/silka-regular-webfont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SilkaMedium";
  src: local("SilkaMedium"),
    url("/fonts/silka-medium-webfont.eot") format("embedded-opentype"),
    url("/fonts/silka-medium-webfont.woff") format("woff"),
    url("/fonts/silka-medium-webfont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SilkaSemibold";
  src: local("SilkaSemibold"),
    url("/fonts/silka-semibold-webfont.eot") format("embedded-opentype"),
    url("/fonts/silka-semibold-webfont.woff") format("woff"),
    url("/fonts/silka-semibold-webfont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "SilkaBold";
  src: local("SilkaBold"),
    url("/fonts/silka-bold-webfont.eot") format("embedded-opentype"),
    url("/fonts/silka-bold-webfont.woff") format("woff"),
    url("/fonts/silka-bold-webfont.ttf") format("truetype");
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: local("Kanit"),
    url("/fonts/kanit-extralight-webfont.woff2") format("woff2"),
    url("/fonts/kanit-extralight-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: local("Kanit"), url("/fonts/kanit-light-webfont.woff2") format("woff2"),
    url("/fonts/kanit-light-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Kanit";
  src: local("Kanit"), url("/fonts/kanit-medium-webfont.woff2") format("woff2"),
    url("/fonts/kanit-medium-webfont.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

body {
  background-color: $color--purple;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Kanit", sans-serif;
  font-weight: 300;

  &.blog,
  &.avisoDePrivacidad {
    background-color: #fff;
  }
}

[disabled] {
  cursor: not-allowed;
  opacity: 0.6;
}

button,
input {
  border-radius: 0;
  border: none;

  &,
  &:active,
  &:focus {
    outline: none;
  }
}

a {
  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    outline: none;
  }
}

@keyframes blink {
  50% {
    opacity: 0.4;
  }
}

.font-primary {
  font-family: "Kanit", sans-serif;

  &--extralight {
    font-weight: 200;
  }

  &--light {
    font-weight: 300;
  }

  &--medium {
    font-weight: 500;
  }
}

.display-1 {
  @media (min-width: 580px) and (max-width: 768px) {
    font-size: 3rem;
  }

  @media (max-width: 579px) {
    font-size: 2.5rem;
  }
}

.display-4 {
  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
}

.ls-1 {
  letter-spacing: 1.23px;
}

.lh-1 {
  line-height: 1;
}

.me-auto {
  margin-right: auto;
}

.font-secondary--light {
  font-family: "SilkaRegular";
}

.font-secondary--medium {
  font-family: "SilkaMedium";
}

.font-secondary--semibold {
  font-family: "SilkaSemibold";
}

.font-secondary--bold {
  font-family: "SilkaBold";
}

.text-pink {
  color: $color--pink;
}

.text-white {
  color: #fff;
}

.text-purple {
  color: $color--purple;
}

.text-purple--alt {
  color: $color--lightpurple;
}

.text-lightpurple {
  color: $color--lightpurple-2;
}

.text-yellow {
  color: $color--yellow2;
}

.text-wo-bg {
  -webkit-text-stroke: 1.5px white;
  color: transparent;
  &.text-thin {
    -webkit-text-stroke: 1px white;
  }

  &.text-yellow {
    -webkit-text-stroke: 1.5px $color--yellow;
  }

  &.text-pink {
    -webkit-text-stroke: 1.5px $color--pink;
  }

  &.text-purple {
    -webkit-text-stroke: 1.5px $color--lightpurple-4;
  }
}

.pseudo-text-pink {
  position: relative;
  @media (min-width: 768px) {
    &::before {
      content: "";
      background: $color--pink-secondary;
      width: 106%;
      height: 60%;
      position: absolute;
      bottom: 0;
      left: -5px;
      z-index: -1;
    }
  }
}

.pseudo-text-mustard {
  position: relative;
  display: inline-flex;
  color: #ffb223;
  @media (min-width: 768px) {
    color: #fff;
    &::before {
      content: "";
      background: $color--mustard;
      width: 106%;
      height: 60%;
      position: absolute;
      bottom: 0;
      left: -5px;
      z-index: -1;
    }
  }
}

.title-border {
  display: inline-block;
  font-size: 16px;
  padding: 21px 31px 20px;
  border: 5px solid $color--blue;
}

.mb-n {
  @media (min-width: 769px) {
    margin-top: 50px;
    margin-bottom: -50px;
  }
}

.rectangle {
  &.rectangle--yellow {
    width: 214px;
    height: 10px;
    margin: 74px 0 45px 0;
    background-image: linear-gradient(to right, #09091a -2%, $color--yellow2);
  }
}

.tbi-btn {
  color: #fff;
  font-family: "SilkaMedium", sans-serif;
  font-weight: 300;
  padding: 0.5rem 1.2rem;
  display: inline-block;
  background-color: $color--lightpurple-2;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  &.tbi-btn--semibold {
    font-family: "SilkaSemibold", sans-serif;
    font-weight: normal;
    font-size: 14px;
    padding-right: 2rem;
    padding-left: 2rem;
  }

  &.tbi-btn--sm {
    font-size: 80%;
  }

  &.tbi-btn--yellow {
    background-color: $color--yellow2;
    color: $color--purple;
  }

  &.tbi-btn--pink {
    background-color: $color--pink;
    color: $color--purple;
  }

  &.tbi-btn--special {
    background: transparent;
    position: relative;

    svg {
      display: block;
      transform: rotate(-90deg);
      fill: #ffffff;
      margin: 20px auto;
    }

    a {
      color: #ffffff;
    }

    .bottom-rectangle {
      width: 158px;
      height: 50px;
      margin: -50px 5px 5px 5px;
      border: solid 5px $color--blue;
      position: relative;
      z-index: -2;

      &:before,
      &:after {
        content: "";
        width: 158px;
        height: 3px;
        position: absolute;
        background: $color--blue;
        top: calc(50% - 3px);
      }

      &:before {
        left: -107%;
      }

      &:after {
        left: 100%;
      }
    }

    &:before {
      position: absolute;
      content: "";
      width: 45%;
      left: 27.5%;
      top: -10%;
      margin: auto;
      height: 70%;
      z-index: -1;
      background-color: $color--pink-tertiary;
    }

    &:after {
      content: "";
      width: 3px;
      position: absolute;
      height: 118px;
      background-color: $color--blue;
      margin-left: 10px;
      margin-top: -5px;
    }
  }
}

.min-h-25 {
  min-width: 25px;
}

.animated-btn {
  color: #fff;
  font-family: "SilkaBold";
  padding: 0.3rem 1.8rem;
  display: inline-block;
  position: relative;
  text-align: center;
  background-color: transparent;

  &:before {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 50px;
    height: 2px;
    transition: all 0.3s ease-in-out;
    background-color: $color--pink;
  }

  &-yellow {
    &:before {
      background-color: $color--yellow;
    }
  }

  span {
    display: inline-block;
    transform: translateX(30px);
    transition: all 0.3s ease-in-out;
  }

  &:hover {
    color: #fff;

    span {
      transform: translateX(0);
    }

    &:before {
      width: 100%;
      height: 100%;
    }
  }

  &.animated-btn--purple {
    color: $color--lightpurple-2;

    &:hover {
      color: #fff;
    }
  }
}

.slider-indicator {
  position: absolute;
  width: 50px;
  background-color: $color--pink;
  top: 0;
  right: 0;
  text-align: center;
  color: #fff;
  padding: 1rem 0.5rem;

  .slider-indicator__bar {
    position: relative;
    text-align: center;

    &:before {
      content: "";
      display: block;
      height: 160px;
      width: 1px;
      background-color: #fff;
      left: 0;
      right: 0;
      margin: auto;
    }

    span {
      position: absolute;
      width: 3px;
      height: 25%;
      background-color: #fff;
      top: 0;
      left: 0;
      right: 0;
      margin: auto;
      transition: all 0.3s ease-in-out;
    }
  }
}

.modal-backdrop {
  background-color: $color--purple;

  &.show {
    opacity: 1;
  }
}

/* purgecss start ignore */
#hubspot-messages-iframe-container.widget-align-right {
  margin-right: 1% !important;
  bottom: 2% !important;
}
/* purgecss end ignore */
