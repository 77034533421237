.single-post-header {
  position: relative;
  background-image: url("/img/blog-texture.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 90%;
    background-color: $color--purple;
    top: 0;
    left: 0;
    z-index: -1;
    @media (min-width: 992px) {
      height: 80%;
    }
  }
  .post-header-img {
    width: 100%;
    position: relative;
    min-height: 520px;
    &:after {
      content: "";
      display: block;
      padding-top: 50%;
    }
    &--withVideo {
      &:after {
        padding-top: 0;
      }
    }
    span {
      display: none;
      @media (min-width: 992px) {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-100%);
        width: 109px;
        height: 109px;
        display: flex;
        align-items: center;
        justify-content: center;
        svg {
          width: 65%;
          height: auto;
        }
      }
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
  h1 {
    font-size: 1.6rem;

    @media (min-width: 768px) {
      font-size: 2.5rem;

    }
    &.tituloBlog{
      @media (min-width: 768px) {
        min-height: 4rem;
      }
    }
  }
}

.topBlog{
  @media (min-width: 768px) {
    min-height: 4rem;
  }
}

.article-quote {
  .quote-bottom {
    transform: rotate(180deg);
  }
  h3 {
    font-size: 1.5rem;
    @media (min-width: 768px) {
      font-size: 1.75rem;
    }
  }
}

.slider-text {
  .slider-text__slider-wrapper {
    @media (min-width: 992px) {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 42%;
    }
    .slider-text__slider {
      .slider-text__slide {
        position: relative;
        &:after {
          content: "";
          display: block;
          padding-top: 71%;
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: contain;
        }
      }
      .slick-slide {
        line-height: 0;
      }
      .slick-arrow {
        position: absolute;
        right: 0;
        z-index: 2;
        width: 35px;
        height: 35px;
        background-color: $color--yellow;
        color: #fff;
        border: none;
        transition: all 0.3s ease-in-out;
        &.slick-prev {
          bottom: 35px;
        }
        &.slick-next {
          bottom: 0;
        }
        &:hover {
          background-color: #f6ad40;
        }
      }
    }
  }
  .slider-text__spacer {
    &:after {
      content: "";
      display: block;
      padding-top: 93%;
    }
  }
}

.draggable-slider {
  position: relative;
  &:before {
    content: "";
    display: block;
    width: 82%;
    height: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background-color: $color--yellow;
    @media (min-width: 992px) {
      width: 70%;
    }
  }
  .draggable-slider__instruction {
    position: absolute;
    left: calc(35% - 1.5rem);
    top: 0;
    bottom: 0;
    transform: rotate(-90deg);
    margin: auto;
  }
  .draggable-slider__slider {
    .draggable-slider__slide {
      width: 100%;
      position: relative;
      &:after {
        content: "";
        display: block;
        padding-top: 64%;
      }
      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
        top: 0;
        left: 0;
      }
    }
  }
}

.blog {
  article {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "SilkaBold";
      color: $color--pink;
    }
    p {
      text-align: justify;
    }
  }
}

.block-heading {
  h2 {
    font-size: 1.6rem;
    @media (min-width: 768px) {
      font-size: 2rem;
    }
  }
}

.blog-single {
  overflow: hidden;
  .share-buttons {
    ul {
      li {
        a {
          background-color: #d8d8d8;
          color: $color--lightpurple-2;
        }
      }
    }
    .share-text {
      color: $color--pink;
      &:before {
        background-color: $color--pink;
      }
    }
  }
  .blog-tags {
    span {
      background-color: $color--lightpurple;
      border-radius: 2px;
      line-height: 1rem;
      display: inline-block;
      margin: 0px 0px 3px 0px;
    }
  }
}

.blog__mainVideo,
.blog__videoContainer {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}

.blog__videoContainer {
  margin: 30px auto;
}

.blog__mainVideo iframe,
.blog__videoContainer iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.blog__article {
  a {
    display: inline-block;
    height: 21px; // for bottom border
    color: $color--pink;
    border-bottom: dotted 1px $color--pink;
    transition: all 0.1s;
    line-break: anywhere;
    &:hover {
      height: 24px;
      color: darken($color--pink, 20%);
      border-bottom-style: solid;
    }
  }
  @media (max-width: 768px) {
  figure{
    img{
      width:100%;
      height: auto;
    }
  }
  }
  strong,
  b {
    color: $color--lightpurple;
  }
  em,
  i {
    display: inline-block;
    padding: 0 5px;
    background-color: rgba($color--yellow, 0.4);
  }
}

