.recent-posts {
  content-visibility: auto;

  .recent-posts__wrapper {
    position: relative;

    &:before {
      content: "";
      display: block;
      width: 88%;
      height: 70%;
      background-color: $color--lightpurple;
      position: absolute;
      top: 1.5rem;
      right: 35px;
      margin: auto;

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  .recent-posts__controls {
    @media (min-width: 992px) {
      width: 50px;
    }

    button {
      width: 50px;
      height: 50px;
      color: #fff;
      background-color: $color--yellow;
      border: none;
      transition: all 0.3s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #f6a836;
      }

      @media (max-width: 769px) {
        transform: translateX(-1rem);
      }

      .fa-chevron-left {
        @media (min-width: 769px) {
          display: none;
        }
      }

      .fa-chevron-right {
        @media (min-width: 769px) {
          display: none;
        }
      }

      .fa-chevron-up {
        @media (max-width: 768px) {
          display: none;
        }
      }

      .fa-chevron-down {
        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }

  .post-content {
    position: relative;
    background-color: #fff;
    width: 100%;

    @media (min-width: 992px) {
      width: calc(100% - 50px);
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: url("/img/recent-posts-bg.jpg");
      background-size: cover;
      background-position: center;
      opacity: 0.05;

      @media (max-width: 769px) {
        opacity: 0.15;
      }
    }

    .post-content__slide {
      h3 {
        &:after {
          content: "";
          display: block;
          width: 112px;
          height: 3px;
          background-color: $color--pink;
          max-width: 50%;
          margin-top: 3rem;

          @media (max-width: 769px) {
            margin-top: 0.5rem;
          }
        }
      }

      p {
        &.text-date {
          @media (max-width: 769px) {
            font-size: 1.2rem;
            font-family: 'SilkaRegular';
            font-weight: 600;
            margin-bottom: 0;
          }
        }
        &.text-autor {
          @media (max-width: 769px) {
            font-size: 1.2rem;
            font-family: 'SilkaBold';
          }
        }
      }
    }

    .slick-dots {
      list-style: none;
      margin: 0;
      padding: 0;
      display: flex;

      li {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #c3c3c3;
        margin-right: 0.5rem;
        cursor: pointer;

        button {
          display: none;
        }

        &.slick-active {
          background-color: $color--pink;
        }
      }
    }
  }

  .more-posts {
    background-color: $color--lightpurple-2;
    width: calc(100% - 25px);
    position: relative;

    @media (min-width: 992px) {
      width: calc(50% - 25px);
    }

    @media (max-width: 768px) {
      transform: translateX(1rem);
      width: calc(100% - 15vh);
    }

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 30%;
      height: 2px;
      background-color: #fff;
      left: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }

    &:hover {
      text-decoration: none;
    }
  }

  .post-img__slider {
    width: calc(100% - 25px);

    .post-img__slide {
      position: relative;

      &:before {
        content: "";
        display: block;
        padding-top: 66%;
      }

      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgb(9, 9, 26);
        background: linear-gradient(180deg,
            rgba(9, 9, 26, 0) 50%,
            rgba(9, 9, 26, 1) 100%);
        top: 0;
        left: 0;
        mix-blend-mode: multiply;
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        object-fit: cover;
      }

      a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;
        opacity: 0;
        transition: all 0.3s ease-in-out;

        span {
          width: 45px;
          height: 45px;
          border: solid 3px #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
        }

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
