@media (max-width: 991.98px) {
  .pstGoogle__title {
    font-size: 22px;
    p {
      margin-bottom: 0px;
    }
  }
}
@media (max-width: 991.98px) {
  .pstGoogle__enlace {
    padding-left: 0.6rem !important;
    padding-right: 0.6rem !important;
  }
}

.pstGoogle__textBanner {
  position: relative;
  @media (min-width: 992px) {
    left: -10%;
  }
}
.pstGoogle__imgBanner {
  position: relative;
  @media (min-width: 992px) {
    left: -10%;
    top: -36%;
    height: 800px;
    object-fit: cover;
  }
}
.groupImgs {
  position: relative;

  .bgElement {
    position: absolute;
    z-index: -1;
    right: 0%;
    top: -70%;
  }

  &__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    //min-width: 367px;
    min-height: 130px;
  }
  &__img {
    @media (min-width: 992px) {
      max-width: 425px;
    }
    //min-width: 269px;
    object-fit: cover;
  }
  .ahrefs {
    img {
      width: 269px;
      height: 50px;
    }
  }
}

.cardResultados {
  position: relative;
  @media (min-width: 992px) {
    top: -10rem;
  }
  &__title {
    font-family: "SilkaMedium";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #fff;
    line-height: 1.45;
    text-align: center;
    @media (min-width: 768px) {
      line-height: 2.13;
      font-size: 30px;
      text-align: left;
    }
  }
  &__subtitle {
    font-family: "SilkaRegular";
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #ffb223;
    text-align: center;
    @media (min-width: 768px) {
      text-align: left;
      font-size: 23px;
    }
  }
  .card {
    min-height: 267px;
    @media (min-width: 768px) {
      min-height: 500px;
    }
    background-color: #3b4559;
    border-radius: 0px;
    &-body {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      @media (min-width: 768px) {
        justify-content: flex-start;
      }
      .porcentaje {
        font-family: "SilkaMedium";
        -webkit-text-stroke: 1px #00a9e0;
        font-weight: 600;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        font-style: normal;
        color: #3b4559;
        font-size: 120px;
        @media (min-width: 768px) {
          padding-top: 2rem;
          font-size: 130px;
        }
      }
      .descripcion {
        font-family: "SilkaMedium";
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #fff;
        font-size: 20px;
        @media (min-width: 768px) {
          font-size: 30px;
        }
      }
    }
  }
}
.pstGPosicionamiento {
  @media (max-width: 767.98px) {
    .texto-comunidad {
      margin: 0 auto;
    }
  }
  &__title {
    object-fit: contain;
    font-family: "SilkaMedium";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    font-weight: 600;
    font-size: 23px;
    color: #fff;
    @media (min-width: 768px) {
      -webkit-text-stroke: 1px #fff;
      color: #09091a;
      font-size: 60px;
    }
  }
  &__img {
    min-width: 579px;
    min-height: 323px;
    object-fit: cover;
  }

  &__text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    ul {
      @media (max-width: 767.98px) {
        list-style: none;
      }

      li {
        font-family: "SilkaMedium";
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.55;
        letter-spacing: normal; 
        color: #fff;
        font-size: 20px;
        width: 90%;
        @media (min-width: 768px) {
          font-size: 24px;
        }
        @media (max-width: 767.98px) {
          width: 100%;
          &::before {
            content: "\2022";
            color: #ffb223;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }
      }
    }
  }
}

.pstGoogle__contenido{
  font-family: "SilkaBold";
  color: #fff;
  h3{
    em{
      font-style: normal;
      color: $color--yellow2;
    }
  }
}