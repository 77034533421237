.home-slider {
  .home-slider {
  	&__slide {
		width: 100%;
		min-height: 100vh;
		position: relative;
		// &:before {
		//   content: "";
		//   display: block;
		//   position: absolute;
		//   width: 100%;
		//   height: 100%;
		//   top: 0;
		//   left: 0;
		//   background-color: rgba(9, 9, 26, 0.45);
		//   mix-blend-mode: multiply;
		//   z-index: 2;
		// }
		// &:after {
		//   content: "";
		//   display: block;
		//   position: absolute;
		//   width: 100%;
		//   height: 100%;
		//   top: 0;
		//   left: 0;
		//   background: rgb(0, 0, 0);
		//   background: linear-gradient(
		//     180deg,
		//     rgba(0, 0, 0, 0) 0%,
		//     rgba(9, 9, 26, 1) 95%
		//   );
		//   z-index: 2;
		// }
		&__title {
			@media (max-width: 992px) {
				font-size: 2.3rem;
			}
		}
		.index-video-wrapper {
			opacity: 0.45;
			@media (min-width: 992px) {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				overflow: hidden;
				padding-bottom: 56.25%;
			}
			&,
			img {
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
			}
			img {
				object-fit: cover;
			}
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			&:after {
				content: "";
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				top: 0;
				left: 0;
				background: rgb(9, 9, 26);
				background: linear-gradient(
				180deg,
				rgba(9, 9, 26, 0) 0%,
				rgba(9, 9, 26, 1) 100%
				);
			}
		}
		.slide-content {
		z-index: 3;
		}
	}
  }
}
