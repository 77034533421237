.tbi-footer {
  @media (max-width: 768px) {
    background-color: #21155f;
    padding-top: 30px;
  }

  small {
    color: #fff;
  }

  .footer-decoration {
    position: absolute;
    width: 60px;
    height: 4px;
    background-color: $color--yellow;
    bottom: 0;
    left: 15px;
    max-width: 35%;
  }

  .footer-logo {
    width: 180px;
    max-width: 100%;
    height: auto;
  }

  .footer-menu {
    text-align: center;
    font-size: 80%;

    .footer-menu__item {
      background-color: #151333;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      position: relative;
      font-family: "Kanit", sans-serif;
      font-weight: 300;
      color: #fff;
      transition: all 0.3s ease-in-out;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 2px;
        background-color: #1e1f30;
        position: absolute;
        bottom: 0;
        left: 0;
      }

      &:hover,
      &.active {
        text-decoration: none;
        background-color: $color--lightpurple-2;

        &:after {
          background-color: $color--pink;
        }
      }
    }
  }

  .footer-social {
    list-style: none;
    margin: 0;
    padding: 0;
    line-height: 0;
    @media (max-width: 991.98px) {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
    }
    li {
      display: inline-block;

      a {
        display: block;
        /*background-color: #151333;*/
        background-color: transparent;
        color: #fff;
        width: 42px;
        height: 42px;
        font-size: 1.65rem;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.3s ease-in-out;

        &:hover {
          background-color: $color--lightpurple-2;
        }
      }
    }
  }

  .footer-email {
    color: $color--yellow;
    @media (max-width: 768px) {
      color: #c5b9ad;
    }
  }

  .tbi-btn {
    font-size: 75%;
  }

  &.tbi-footer--alt {
    .footer-social {
      li {
        a {
          width: auto;
          height: auto;

          &,
          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    .footer-email {
      color: $color--pink;
      @media (max-width: 768px) {
        color: #fff;
      }
    }

    .tbi-btn {
      font-size: 75%;
      background-color: transparent;
      padding: 0;
      font-family: "Kanit", sans-serif;
      font-weight: 300;
      color: $color--yellow;

      &:hover {
        transform: scale(1);
      }
    }
  }

  .footer-copy {
    padding-bottom: 3rem;

    @media (min-width: 992px) {
      padding-bottom: 0;
    }
  }

  &:not(.tbi-footer--alt) {
    .footer-copy {
      margin-bottom: 3rem;

      @media (min-width: 992px) {
        margin-bottom: 0;
      }
    }
  }
}

body.blog,
body.avisoDePrivacidad {
  .tbi-footer {
    background-color: #f8f8f8;

    small,
    .footer-social li a {
      color: $color--lightpurple-2;
    }
  }
}

.footer__linksB {
  li a {
    font-size: 14px;
  }
}
