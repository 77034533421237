.header__cotiza {
  background-color: #ffb223;
  color: #000;
  max-width: -webkit-max-content;
  max-width: -moz-max-content;
  max-width: max-content;
  padding: 0.1rem 1rem;
}
.bi-header {
  background-color: rgba(9, 9, 26, 1);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  height: 67px; /***/
  .brand-logo {
    height: 60px;
    display: flex;
    align-items: center;
    width: 250px;
    .brand-logo__full,
    .brand-logo__short {
      height: 85%;
      width: auto;
    }
    .brand-logo__short {
      width: 40px;
      display: none;
      opacity: 0;
      transform: translateY(100%);
      pointer-events: none;
    }

    @media (max-width: 991px) {
      z-index: 5;
      justify-content: center;
      text-align: center;
      margin: 0 auto;
      width: 100%;
      margin-right: -30px;
    }
  }

  .bi-header-container {
    @media (max-width: 991px) {
      max-width: 100%;
    }
    &.active-menu {
      max-width: 100%;
    }
  }

  &.headroom {
    will-change: transform;
    transition: transform 200ms linear;
  }
  &.headroom--pinned {
    transform: translateY(0%);
  }
  &.headroom--unpinned {
    transform: translateY(-100%);
  }

  .bi-header__nav {
    list-style: none;
    display: flex;
    margin: 0;
    padding: 0;
    margin-left: auto;
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: $color--purple;
    z-index: -1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateX(-100%);
    transition: all 0.3s ease-in-out;
    &.show-menu {
      transform: translateY(0);
    }
    @media (min-width: 768px) {
      position: relative;
      height: auto;
      background-color: transparent;
      z-index: 0;
      flex-direction: row;
      justify-content: flex-end;
      transform: translateX(0);
    }
    li {
      &:not(:last-child) {
        margin-bottom: 1rem;
        @media (min-width: 768px) {
          margin-bottom: 0;
          margin-right: 1.5rem;
        }
      }
      a {
        color: #fff;
        text-decoration: none;
        transition: all 0.3s ease-in-out;
        font-size: 1.5rem;
        letter-spacing: 0.2em;
        @media (min-width: 992px) {
          font-size: 1rem;
          padding: 0.4rem 0.6rem;
          &.active {
            background-color: $color--yellow;
            color: $color--purple;
          }
        }
        &:not(.active):hover {
          color: $color--yellow;
        }
      }
    }
  }
}
