#newsletter-modal {
  .modal-content {
    background-color: $color--purple;
    form {
      input {
        width: 100%;
        background-color: white;
        padding: 0.3rem 1rem;
        border-radius: 5px;
      }
      button {
        background-color: $color--yellow;
        color: $color--lightpurple-2;
      }
      ::placeholder {
        font-family: "SilkaMedium", sans-serif;
        font-weight: 500;
        color: #8487b0;
      }
    }
    .form-message {
      opacity: 0;
    }
    .newsletter-copy {
      background-color: #f6f6f6;
      position: relative;
      padding-bottom: calc(1.5rem + 17px);
      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 17px;
        background-color: $color--pink;
        position: absolute;
        bottom: 0;
        left: 0;
      }
      @media (min-width: 992px) {
        font-size: 1.5rem;
      }
      span {
        background-color: $color--pink;
        color: #fff;
        width: 56px;
        height: 56px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        right: 0;
        border-radius: 50%;
        transform: translate(50%, -50%);
      }
    }
    .newsletter-modal-decoration {
      position: absolute;
      height: 100%;
      width: 200%;
      background-color: #3b44b2;
      right: 0;
      top: 0;
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("/img/newsletter-modal-bg.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.1;
      }
    }
    .newsletter-left-col {
      z-index: 2;
    }
    .close {
      position: absolute;
      top: 0;
      right: 0;
      opacity: 1;
    }
  }
}
