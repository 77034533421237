.avisoDePrivacidad {
  .content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "SilkaBold";
      color: $color--purple;
    }
    article {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-family: "SilkaBold";
        color: $color--pink;
      }
      p {
        text-align: justify;
      }
    }

    a {
      display: inline-block;
      height: 21px; // for bottom border
      color: $color--pink;
      border-bottom: dotted 1px $color--pink;
      transition: all 0.1s;
      &:hover {
        height: 24px;
        color: darken($color--pink, 20%);
        border-bottom-style: solid;
      }
    }
    strong,
    b {
      color: $color--lightpurple;
    }
    em,
    i {
      display: inline-block;
      padding: 0 5px;
      background-color: rgba($color--yellow, 0.4);
    }
  }
}
