.sidebar {
  width: 100%;
  flex: 0 0 100%;
  max-width: 100%;
  justify-content: space-between;
  @media (min-width: 992px) {
    width: 23.68%;
    flex: 0 0 23.68%;
    max-width: 23.68%;
    margin-left: auto;
  }
  .sidebar-cat {
    width: 45%;
    flex: 0 0 45%;
    max-width: 45%;
    background-size: cover;
    background-position: center;
    display: block;
    position: relative;
    @media (min-width: 992px) {
      width: 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    &:after {
      content: "";
      display: block;
      padding-top: 100%;
    }
    span {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
    .cat-title {
      position: absolute;
      top: 32.04%;
      bottom: 32.04%;
      left: 0;
      right: 0;
      margin: auto;
      width: 35.92%;
      font-size: 0.6rem;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      &:after {
        content: "";
        display: block;
        padding-top: 100%;
      }
    }
    &:hover {
      span {
        opacity: 0.6;
      }
    }
  }
  .posts-pagination {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    order: -1;
    @media (min-width: 992px) {
      order: 0;
    }
    .posts-pagination__numbers {
      width: 60.37%;
      flex: 0 0 60.37%;
      max-width: 60.37%;
      background-color: $color--lightpurple-2;
    }
    .posts-pagination__buttons {
      width: 39.63%;
      flex: 0 0 39.63%;
      max-width: 39.63%;
      a {
        width: 50%;
        flex: 0 0 50%;
        max-width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color--pink;
        color: #fff;
        &:hover {
          background-color: #fb6053;
        }
      }
    }
  }
}
