.contact-banner {
  background-color: $color--lightpurple;
  position: relative;
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: url("/img/contact-banner-bg.jpg");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    opacity: 0.03;
  }
  @media (max-width: 991.98px) {
    h2 {
      font-size: 1.4rem;
    }
  }
}
