.quote-page-title {
  h1 {
    position: relative;
    &:after {
      @media (min-width: 992px) {
        content: "";
        display: block;
        width: 35%;
        height: 1px;
        background-color: #fff;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
      }
    }
  }
}

.quote-email {
  @media (min-width: 992px) {
    transform: rotate(-90deg);
    display: block;
    white-space: nowrap;
  }
}

.cotiza {
  .contact-form {
    .form-group {
      opacity: 0;
      pointer-events: none;
      &.name{
          opacity: 1 !important;
          pointer-events: all !important;
      }
    }
  }
}

.ff-form-success{
  color: #da9b41 !important;
  font-weight: 400;
}
