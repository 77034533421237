.downloads__cardFooterBtn {
	&--modal {
		.fa-envelope {
			display: initial;
		}

		.fa-file-download {
			display: none;
		}
	}

	&--download {
		.fa-envelope {
			display: none;
		}

		.fa-file-download {
			display: initial;
		}
	}
}

.downloads__card {
	border-radius: 0;

	&Body {
		height: 170px;
		color: white;
		background-position: center;
		background-size: cover;
	}

	&FooterBtn {
		border-radius: 0;
		border: 0;
		background-color: $color--lightpurple;

		&:hover {
			background-color: lighten($color--lightpurple, 5%);
		}

		&.clicked {
			background-color: $color--yellow;

			&:hover {
				background-color: lighten($color--yellow, 5%);
			}

			&:active,
			&:focus {
				box-shadow: 0 0 0 0.2rem rgba($color--yellow, 0.3);
			}
		}
	}
}

.animated {
	-webkit-animation-duration: 0.5s;
	animation-duration: 0.5s;
	-webkit-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-timing-function: linear;
	animation-timing-function: linear;
}
.animated-infinite {
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
}

@-webkit-keyframes bounce {
	0%,
	100% {
		-webkit-transform: translateY(0);
	}

	50% {
		-webkit-transform: translateY(-5px);
	}
}

@keyframes bounce {
	0%,
	100% {
		transform: translateY(0);
	}

	50% {
		transform: translateY(-5px);
	}
}

.bounce {
	-webkit-animation-name: bounce;
	animation-name: bounce;
}

.modal-backdrop {
	&.show {
		opacity: 0.95;
	}
}
