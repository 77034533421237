.case-studies {
  h2 {
    font-size: 3.5rem;
  }
  p,
  .case-studies__btn {
    z-index: 3;
    position: relative;
  }
  .tbi-btn {
    background-color: $color--yellow;
    color: $color--purple;
    font-size: 80%;
  }
  .case-studies__img {
    z-index: 2;
    position: relative;
    &:after {
      content: "";
      display: block;
      padding-top: 45%;
    }
    @media (min-width: 992px) {
      position: absolute;
      width: 155%;
      height: 90%;
      left: 45%;
      bottom: 0;
      top: 0;
      margin: auto;
      &:after {
        display: none;
      }
    }
    img {
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
