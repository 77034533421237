.modal-content {
	color: white;
	background-color: transparent;
}
.close {
	img {
		width: 100%;
		height: 100%;
	}
}
