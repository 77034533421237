.contact-form {
  input {
    background-color: transparent;
    border: none;
    color: #fff;
    font-family: "Kanit", sans-serif;
    font-weight: 200;
    width: 100%;
    &[type="radio"] {
      display: none;
      & + label {
        color: #b6b6b6;
      }
      &:checked + label {
        color: $color--yellow;
        font-weight: 500;
      }
    }
  }
  ::placeholder {
    color: #b6b6b6;
    font-family: "Kanit", sans-serif;
    font-weight: 200;
  }
  .reach-options {
    .reach-option {
      span {
        color: #b6b6b6;
      }
      label {
        font-family: "Kanit", sans-serif;
        font-weight: 200;
      }
    }
  }
  .select2-container--default .select2-selection--multiple {
    background-color: transparent;
    border: none;
    .select2-selection__choice {
      background-color: $color--lightpurple-2;
      border-radius: 0;
      color: #fff;
      border-color: #fff;
      padding: 0.5rem 1.2rem;
      font-family: "Kanit", sans-serif;
      font-weight: 200;
      font-size: 80%;
    }
    .select2-selection__choice__remove {
      color: #fff;
      margin-right: 0.8rem;
    }
  }
  button {
    background-color: $color--yellow;
    border: none;
    letter-spacing: 0.2em;
    padding: 0.4em 0.6em;
  }
  .loading-icon {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    &.show {
      opacity: 1;
    }
  }
  .form-message {
    opacity: 0;
  }
  .select-dropdown {
    background-color: transparent;
    border-radius: 0;
    border: none;
    font-family: "Kanit", sans-serif;
    font-weight: 200;
    color: #b6b6b6;
    option {
      background-color: #21145d;
      color: #fff;
    }
    &:focus,
    &:active {
      outline: none;
    }
  }
}

.select2-dropdown {
  background-color: $color--purple;
  border-radius: 0;
  border: none;
  font-family: "Kanit", sans-serif;
  font-weight: 200;
  color: #fff;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option[aria-selected="true"] {
  background-color: $color--lightpurple-2;
}

.contact-banner-custom {
  &-Title {
    line-height: 1.47;
  }

  .img-form {
    width: 425px;
    height: 508px;
  }
  .custom-form {
    label {
      font-size: 18px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #fff;
      font-family: "SilkaMedium";
      .labelRequired {
        color: #ff6359;
      }
    }
    .form-control {
      border: 0px;
      border-radius: 0px;
      box-shadow: none;
      color: #fff;
      width: 100%;
      background-color: transparent !important;
      &,
      &::placeholder {
        font-family: "SilkaRegular";
        font-size: 16px;
        font-weight: 300;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #c3c3c3;
      }
    }

    select.form-control option {
      background-color: rgba(236, 236, 236, 0.3);
      color: #09091a;
      text-shadow: 0 1px 0 rgba(236, 236, 236, 0.3);
    }

    .submit-btn {
      font-family: "SilkaMedium";
      background-color: #ffb223;
      font-size: 15px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.6;
      letter-spacing: normal;
      color: #09091a;
    }
    .form-check-input {
      &[type="radio"],
      &[type="checkbox"] {
        opacity: 0;
        position: fixed;
        width: 0;
        &:checked + label {
          background-color: #ffb223;
          border-color: #ffb223;
        }
      }
    }
    label.form-check-label {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: rgba(236, 236, 236, 0.3);
      font-family: "SilkaMedium";
      height: 36px;
      border: 0px;
      border-radius: 0px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2;
      letter-spacing: normal;
      text-align: center;
      color: #09091a;
    }
  }
  .freeform-form-has-errors,
  .ff-form-errors {
    font-family: "SilkaMedium";
    color: #fff;
  }
}


.contact-banner-custom{
  .animated {
    opacity: 0;
  }
}