.our-methodology {
  h2 {
    display: inline-block;
    &:after {
      content: "";
      display: block;
      width: 35%;
      height: 4px;
      margin-top: 1.5rem;
      background-color: $color--pink;
    }
  }
  .prev-arrow,
  .next-arrow {
    background-color: transparent;
    border: none;
    svg {
      path {
        transition: fill 0.3s ease-in-out;
      }
    }
    &:hover {
      svg {
        path {
          fill: $color--pink;
        }
      }
    }
  }
  .next-arrow {
    transform: rotate(180deg);
  }
}

.our-methodology {
  position: relative;
  @media (min-width: 768px) {
    &:after {
      content: "";
      display: block;
      pointer-events: none;
      position: absolute;
      width: 100%;
      height: 1px;
      opacity: 0.6;
      bottom: 10%;
      border-bottom: solid 0.05em rgba(255, 255, 255, 0.62);
    }
  }
  .methodology-controls {
    padding-bottom: 3.4%;
  }
  .methodology-steps {
    .slick-list {
      padding-right: 15%;
    }
    .step-wrapper {
      position: relative;
      padding-top: 10rem;
      .step-number {
        font-size: 10rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        margin: auto;
        color: rgba(255, 181, 73, 0.1);
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(255, 181, 73, 0.18);
      }
    }
    .methodology-step {
      background-color: $color--lightpurple;
      position: relative;
      overflow: hidden;
      &:after {
        content: "";
        display: block;
        padding-top: 125%;
      }
      h3 {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
      }
      svg {
        position: absolute;
      }
      &-1 {
        svg {
          width: 95%;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }
      &-2 {
        svg {
          width: 95%;
          height: auto;
          right: 0;
        }
      }
      &-3 {
        svg {
          width: 130%;
          height: auto;
          left: 2%;
          top: 2%;
        }
      }
      &-4 {
        svg {
          width: 90%;
          height: auto;
          top: 5%;
          left: 5%;
        }
      }
      &-5 {
        svg {
          left: 2.5%;
          width: 95%;
          top: 2%;
          height: auto;
        }
      }
      &-6 {
        svg {
          top: 3%;
          width: 95%;
          height: auto;
          left: 2.5%;
        }
      }
    }
  }
}
