.container-404 {
  width: 100%;
  height: 90vh;
  min-height: 600px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &__error {
    font-size: 1.1rem;
    font-weight: 600;
    font-stretch: normal;
    color: white;
    font-family: SilkaRegular;
    text-align: center;
  }

  &__text {
    font-size: 2.25rem;
    color: white;
    font-family: SilkaRegular;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    text-align: center;

    &--blog {
      font-size: 1.25rem;
      text-align: left;
    }
  }
}
