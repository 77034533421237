.blog {
  .scroll-indicator {
    position: fixed;
    bottom: 10px;
    left: 0;
    z-index: 100;
    pointer-events: none;
    .container {
      animation: blink 1.2s ease-out infinite forwards;
    }
  }
}
