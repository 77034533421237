.blog {
  .post-list {
    width: 100%;
    flex: 0 0 100%;
    max-width: 100%;
    @media (min-width: 992px) {
      width: 70.8%;
      flex: 0 0 70.8%;
      max-width: 70.8%;
    }
    .post-card {
      .post-card__img {
        position: relative;
        &:after {
          content: "";
          display: block;
          padding-top: 85%;
          background-color: #000;
          opacity: 0.6;
          transition: all 0.3s ease-in-out;
          @media (min-width: 768px) {
            padding-top: 52%;
          }
        }
        img {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          object-fit: cover;
          mix-blend-mode: unset;
          transition: all 0.3s ease-in-out;
        }
      }
      .post-card__overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
      .post-card__content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        right: 0;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: center;
        h2 {
          font-size: 1.3rem;
          @media (min-width: 768px) {
            font-size: 2rem;
          }
        }
        @media (min-width: 768px) {
          width: 70%;
        }
      }
      .post-card__meta {
        background-color: #d5d5d5;
        color: #292a2b;
      }
      &:hover {
        .post-card__img {
          &:after {
            opacity: 0;
          }
          img {
            mix-blend-mode: multiply;
          }
        }
        // .post-card__overlay {
        //   opacity: 0.6;
        // }
      }
    }
    .post-card--half {
      .post-card {
        .post-card__img {
          &:after {
            @media (min-width: 768px) {
              padding-top: 64.61%;
            }
          }
        }
        .post-card__content {
          h2 {
            @media (min-width: 768px) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
  }
}
