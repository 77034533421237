.bg-triangle {
  background-image: -webkit-linear-gradient(125deg, #3b4559 50%, transparent 0);

  @media (max-width: 768px) {
    background-image: unset;
  }
}

.serviciosB2c {
  overflow-x: hidden;
}

.service-landing.pstGoogle {
  .stone {
    background: linear-gradient(0deg, $color--purple 50%, #ffb223 50%);
  }
  .service-landing__slider--slide-title {
    em {
      font-style: normal;
      color: #ffb223;
    }
  }
}

.service-landing {
  &__banner {
    height: 100vh;

    @media (max-width: 768px) {
      height: auto;
    }

    &.blue {
      background: linear-gradient(
        0deg,
        $color--purple 50%,
        $color--lightpurple-2 50%
      );
    }

    &.stone {
      background: linear-gradient(0deg, $color--purple 50%, $color--stone 50%);
    }

    &--container {
      padding-top: 15vh;

      .bullets-servicio {
        ul {
          li {
            text-align: left;
          }
        }
      }

      .b2c_conoce_titulo {
        @media (max-width: 768px) {
          margin-right: 0;
        }

        @media (max-width: 1200px) {
          margin-right: -13vh;
        }
      }

      .title-border {
        font-family: "SilkaMedium";

        @media (max-width: 768px) {
          padding: 1.5rem 4rem;
          font-size: 1.65rem;
        }

        @media (max-width: 576px) {
          padding: 0.5rem;
          font-size: 1.2rem;
        }
      }

      @media (max-width: 768px) {
        padding-top: 66px;

        .text-wo-bg {
          color: #fff;
          -webkit-text-stroke: unset;
          font-size: 1.6rem;
        }

        .bullets-servicio {
          ul {
            font-size: 1.16rem;

            li {
              margin-bottom: 0.6rem;
              text-align: left;

              &::marker {
                color: #3b3fb6;
              }
            }
          }
        }

        .btn-propuesta {
          @media (max-width: 768px) {
            margin-bottom: 2rem;

            &:before {
              width: 100%;
              height: 100%;
              background-color: #ffb223;
            }

            span {
              transform: translateX(0px);
              text-transform: uppercase;
              color: black;
              padding: 1rem 0.5rem;
              font-family: "SilkaSemibold";
            }
          }
        }
      }
      @media (max-width: 767.98px) {
        .bullets-servicio {
          ul {
            big {
              border-image: none;
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }

  &__slider {
    max-height: 70vh;
    box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.24);

    @media (max-width: 768px) {
      max-height: 60vh;
      overflow: hidden;
    }

    &--slide {
      background-blend-mode: multiply;
      padding: 15rem;
      height: 100%;
      width: 100%;
      max-height: 90vh;
      background-size: cover !important;

      @media (max-width: 768px) {
        /*  padding: 5rem 2.5rem;*/
        padding: 10rem 4rem;
        min-height: 60vh;
      }

      &-title {
        &:after {
          content: "";
          height: 3px;
          width: 20%;
          display: block;
          background-color: white;
          margin-top: 1rem;
        }
      }

      .slide-link {
        &:hover {
          .slide-arrow {
            padding-left: 20px;
            transition: 0.5s padding-left;
          }
        }

        .slide-arrow {
          width: 45px;
          padding-left: 0px;
          transition: 0.5s padding-left;

          svg {
            transform: rotate(180deg);
          }
        }
      }
    }

    &--controls {
      z-index: 1;
      position: absolute;
      top: 15%;
      left: 15rem;

      @media (max-width: 1200px) {
        right: 40px;
        left: auto;
        top: unset;
        bottom: -15px;
      }

      .slider-control {
        &-left {
          padding-left: 20px;
          background: none;

          &:after {
            content: "";
            display: block;
            background: $color--lightpurple-3;
            width: 60px;
            height: 25px;
            position: absolute;
            left: 11px;
            bottom: 14px;
            z-index: -1;
          }
        }

        &-right {
          transform: rotate(180deg);
          padding-right: 20px;
          background: none;
          padding-bottom: 2px;

          &:after {
            content: "";
            display: block;
            background: $color--lightpurple-3;
            width: 60px;
            height: 25px;
            position: absolute;
            left: 0px;
            top: 14px;
            z-index: -1;
          }
        }

        * {
          fill: white;
        }
      }
    }
  }

  &__square-logo {
    background-color: white;
    width: 100px;
    height: 100px;
    position: absolute;
    z-index: 1;
    margin-left: -50px;
    margin-top: calc(15% - 100px);

    @media (max-width: 768px) {
      width: 75px;
      height: 75px;
      margin-left: 40px;
      margin-top: 0;
      bottom: -40px;

      svg {
        width: 60%;
        height: 60%;
      }
    }

    @media (max-width: 500px) {
      width: 50px;
      height: 50px;
      bottom: -25px;
    }

    &:after {
      @media (max-width: 768px) {
        display: none;
      }

      content: "";
      display: block;
      position: absolute;
      width: 3px;
      height: 40px;
      bottom: -70px;
      background: white;
      left: 20px;
    }
  }

  .slick-dots {
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 2rem 0;
    list-style-type: none;

    li {
      margin: 0 0.25rem;
    }

    button {
      display: block;
      width: 0.6rem;
      height: 0.6rem;
      padding: 0;

      border: none;
      border-radius: 100%;
      background-color: white;
      opacity: 0.4;

      text-indent: -9999px;
    }

    li.slick-active button {
      background-color: white;
      opacity: 1;
    }
  }
}

.project {
  position: relative;
  height: 100%;
  text-align: center;
  padding: 80px;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  flex-direction: column;
  min-height: 75vh;
  transition: all 0.2s ease-in-out;

  @media (max-width: 768px) {
    height: 300px;
    padding: 30px;
    min-height: 420px;
  }

  /*
  @media (min-width: 769px) and (max-width: 999px) {
    padding: 60px;
    height: 580px;
  }*/

  @media (min-width: 1000px) and (max-width: 1380px) {
    padding: 60px;
    height: 500px;
  }

  .project-social {
    transition: all 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    z-index: 4;

    ul {
      display: inline;
      margin: 0;
      padding: 0;

      li {
        display: inline;
        margin: 0;
        padding: 0;
      }
    }

    a {
      text-decoration: none;
      color: #fff;
      font-size: 2.5rem;
    }
  }

  &:hover {
    .img-overlay {
      background: #000;
      opacity: 0.5;
    }

    .project-title {
      opacity: 1;
    }

    .project-social {
      opacity: 1;
      pointer-events: all;
    }

    .project-info {
      opacity: 1;
    }
  }

  @media (max-width: 769px) {
    .img-overlay {
      background: #000;
      opacity: 0.5;
    }

    .project-social {
      opacity: 1;
      pointer-events: all;
    }
  }

  .img-overlay {
    background-image: -moz-linear-gradient(
      top,
      rgba(67, 67, 67, 0),
      rgba(0, 0, 0, 0.55)
    );
    background-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(rgba(67, 67, 67, 0)),
      to(rgba(0, 0, 0, 0.55))
    );
    background-image: -webkit-linear-gradient(
      top,
      rgba(67, 67, 67, 0),
      rgba(0, 0, 0, 0.55)
    );
    background-image: -o-linear-gradient(
      top,
      rgba(67, 67, 67, 0),
      rgba(0, 0, 0, 0.55)
    );
    background-image: linear-gradient(
      to bottom,
      rgba(67, 67, 67, 0),
      rgba(0, 0, 0, 0.55)
    );
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: 0 0;
    -webkit-transition: all 0.2s linear;
    -moz-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
    transition: all 0.2s linear;

    width: 100%;
    position: absolute;
    top: 0;
    height: 100%;
    left: 0;
    z-index: 2;

    &:hover {
      background: #000;
      opacity: 0.6;
    }
  }

  .project-image {
    position: absolute;
    object-fit: cover;
    z-index: -1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
    }
  }

  h3 {
    font-weight: lighter;
  }

  .project-title {
    transition: all 0.2s ease-in-out;
    z-index: 3;

    @media (max-width: 769px) {
      font-size: 1.65rem;
      font-family: "SilkaMedium";
    }
  }

  .project-info {
    transition: all 0.2s ease-in-out;
    z-index: 3;
    padding: 25px;
    width: 100%;
    display: flex;
    border: 3px solid #fff;
    justify-content: space-around;
    flex-direction: row;

    @media (min-width: 769px) and (max-width: 1120px) {
      flex-direction: column;
    }

    .project-info-item {
      display: flex;
      flex-direction: column;

      .project-icon {
        height: 25px;
        margin-bottom: 15px;
      }
    }
  }
}

.mockup-container {
  @media (max-width: 769px) {
    width: 100%;
    max-width: none;
    padding: 0;
    margin: 0;
  }
}

.mockup {
  @media (max-width: 768px) {
    width: 100%;
    max-width: unset;
  }

  @media (min-width: 769px) {
    margin-top: -40vh;
  }

  @media (min-width: 1080px) {
    margin-left: 0;
  }

  @media (min-width: 1200px) {
    margin-left: -8vh;
  }

  @media (min-width: 1550px) {
    margin-top: -55vh;
    margin-left: -25vh;
  }
}

.texto-comunidad {
  @media (max-width: 768px) {
    text-align: center;
  }

  .font-secondary--bold {
    @media (max-width: 768px) {
      font-family: "SilkaMedium";
      font-size: 1.6rem;
      line-height: 1.33;
    }
    @media (max-width: 767.98px) {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  .rectangle--yellow {
    @media (max-width: 768px) {
      width: 85%;
      height: 20px;
      margin: 0 auto;
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }

  .animated-btn {
    @media (max-width: 768px) {
      &:before {
        width: 100%;
        height: 100%;
        background-color: #ffb223;
      }

      span {
        transform: translateX(0px);
        color: black;
        padding: 1rem 0.5rem;
        font-family: "SilkaSemibold";
      }
    }
  }
}

.customer-logo {
  max-height: 53px;
  max-width: 120px;
  margin: 15px;

  @media (max-width: 768px) {
    max-height: 35px;
    margin: 10px;
  }
}

.marketplaces-logo {
  max-height: 48px;
  max-width: 217px;
  margin: 15px;

  @media (max-width: 768px) {
    max-height: 35px;
    margin: 15px;
  }
}

.boxes-container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(4, 1fr);
    grid-column-gap: 11px;
    grid-row-gap: 11px;
  }

  .box {
    border: 1px solid;
    min-height: 80px;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media (max-width: 768px) {
      padding: 11px;

      img {
        height: 60px;
      }
    }

    h2 {
      @media (max-width: 768px) {
        font-size: 5rem;
      }

      @media (max-width: 580px) {
        font-size: 4rem;
      }
    }

    p {
      margin-bottom: 0;

      @media (max-width: 768px) {
        font-size: 20px;
        font-family: "SilkaMedium";
      }
    }
  }

  .box-1 {
    grid-area: 1 / 1 / 3 / 2;
    border-color: $color--pink;

    @media (max-width: 768px) {
      grid-area: 2 / 1 / 4 / 3;
    }
  }

  .box-2 {
    grid-area: 1 / 2 / 2 / 4;
    border-color: $color--yellow;

    @media (max-width: 768px) {
      grid-area: 1 / 1 / 1 / 5;
    }
  }

  .box-3 {
    grid-area: 2 / 2 / 3 / 3;
    border: none;

    @media (max-width: 768px) {
      display: none;
    }
  }

  .box-4 {
    grid-area: 3 / 1 / 4 / 3;
    border-color: $color--lightpurple-4;

    @media (max-width: 768px) {
      grid-area: 4 / 1 / 4 / 5;
    }
  }

  .box-5 {
    grid-area: 2 / 3 / 4 / 4;
    border-color: $color--pink;

    @media (max-width: 768px) {
      grid-area: 2 / 3 / 4 / 5;
    }
  }
}

@media (max-width: 768px) {
  .scroll-overlay {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 70px;
    z-index: 5;
    pointer-events: none;
    transition: opacity 200ms ease;
  }

  .scroll-overlay:first-of-type {
    left: 0;
    opacity: 0;
    background-image: linear-gradient(
      to right,
      #09091a,
      rgba(255, 255, 255, 0)
    );
  }

  .scroll-overlay:last-of-type {
    right: 0;
    background-image: linear-gradient(to left, #09091a, rgba(255, 255, 255, 0));
  }
}

/*.tab-content {*/
/*@media (max-width: 680px) {*/
/*height: 400px;*/
/*}*/

/*@media (max-width: 400px) {*/
/*height: 250px;*/
/*}*/
/*}*/

.nav-tabs {
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    justify-content: flex-start;
    flex-wrap: nowrap;
    overflow-x: hidden;
    overflow-y: hidden;
    padding: 20px 0 20px 20px;
  }

  .nav-link {
    background: transparent;
    opacity: 0.6;
    border: none;

    &:hover {
      border: none;
      opacity: 0.75;
    }

    &.active {
      opacity: 1;
      background: transparent;
    }
  }
}

.tab-pane {
  display: flex;
  justify-content: center;
}

.cta-banner-cotiza {
  @media (max-width: 768px) {
    background-size: cover !important;
  }

  .pseudo-text-pink {
    display: inline-flex;
  }

  .font-secondary--bold {
    @media (max-width: 768px) {
      font-size: 1.8rem;
      line-height: 1.5;
      font-family: "SilkaMedium";
    }

    @media (max-width: 580px) {
      font-size: 1.4rem;
      line-height: 1.5;
      font-family: "SilkaMedium";
    }
  }
}

.texto-b2b {
  @media (max-width: 768px) {
    .font-secondary--bold.text-wo-bg {
      color: #fff;
      -webkit-text-stroke: unset;
      font-size: 1.67rem;
      font-family: "SilkaSemibold";
    }

    .font-secondary--bold {
      font-size: 1.25rem;
      line-height: 1.5;
      margin-top: 1rem;
      font-family: "SilkaMedium";
    }

    .font-secondary--bold.b2b-sm {
      font-size: 1.1rem;
      line-height: 1.5;
      margin-top: 1rem;
      font-family: "SilkaMedium";
    }
  }
}

.b2b-objetivo {
  @media (max-width: 768px) {
    display: flex;

    img {
      margin-right: 1.5rem;
      width: 50px;
    }

    p {
      width: 100%;
      font-size: 1.15rem;

      br {
        display: none;
      }
    }
  }
}

.b2b-title-mobile {
  @media (max-width: 768px) {
    -webkit-text-stroke: unset;
    color: #fff;
    font-family: "SilkaRegular";
    font-size: 2rem;
    font-weight: 700;
  }
}

.nobr-mobile {
  @media (max-width: 768px) {
    br {
      display: none;
    }
  }
}

.b2b-text-partners {
  @media (max-width: 768px) {
    font-size: 1.34rem;
    font-family: "SilkaRegular";
    line-height: 1.3;
  }
}

.banner-marketplaces {
  h1 {
    font-size: 1.87rem;
    font-family: "SilkaSemibold";
  }
  h3 {
    font-size: 1.87rem;
    font-family: "SilkaSemibold";
  }
}

#marketsTabs {
  padding-top: 70px;

  &.tab-content {
    @media (max-width: 680px) {
      height: auto;
    }

    @media (max-width: 400px) {
      height: auto;
    }
  }

  .marketplaces-heading {
    h2 {
      font-size: 47px;
      text-align: center;
      @media (min-width: 768px) {
        text-align: left;
        font-size: 3.75rem;
      }
    }

    p {
      font-size: 20px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      font-family: "SilkaRegular";
    }
  }
}

.marketplaces-beneficios {
  &--left {
    h2 {
      font-size: 3.75rem;
      @media (max-width: 767.98px) {
        padding-top: 2rem;
        font-size: 24px;
        text-align: center;
        -webkit-text-stroke: 0px;
        color: white;
      }
    }

    p {
      object-fit: contain;
      font-family: "SilkaRegular";
      font-size: 25px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 2.08;
      letter-spacing: normal;
      color: #fff;
      @media (max-width: 767.98px) {
        font-size: 20px;
      }
    }
  }

  &--right {
    img {
      margin-bottom: 20px;
    }

    h3 {
      font-family: "SilkaRegular";
      font-size: 22px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.55;
      letter-spacing: normal;
      color: #ffb223;
    }

    p {
      font-family: "SilkaRegular";
      font-size: 19px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.7;
      letter-spacing: normal;
      color: #fff;
    }
  }
}

.slideQuehacemos {
  &-item {
    width: 262px;
    height: 373px;
    padding: 39px 0 0.3px;
    background-size: cover;
    background-repeat: no-repeat;
  }

  &-title {
    margin-top: 2.6rem;
    font-family: "SilkaRegular";
    font-size: 17px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.45;
    letter-spacing: normal;
    color: #fff;
    -webkit-font-smoothing: antialiased;
    -webkit-filter: blur(0.000001px);
  }

  .swiper-button-next {
    position: relative;
    color: white;
    font-size: 1.7rem;
    right: 0;
    left: 0;
    margin: 0;
    margin-left: 0.5rem;

    &::after {
      display: none;
    }
  }

  .swiper-button-prev {
    position: relative;
    color: white;
    font-size: 1.7rem;
    left: 0;
    margin: 0;

    &::after {
      display: none;
    }
  }
}

.bloqueIdeal {
  &-title {
    font-size: 30px;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.13;
    letter-spacing: normal;
    color: #fff;
    @media (max-width: 767.98px) {
      padding-top: 2rem;
      padding-bottom: 1rem;
      font-size: 23px;
      text-align: center;
    }
  }
  &-copy {
    font-family: "SilkaRegular";
    letter-spacing: 1px;
    font-size: 25px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    color: #fff;
    @media (max-width: 767.98px) {
      font-size: 20px;
    }
  }

  .animated-btn {
    font-size: 1.3rem;
  }
  &-Btn {
    display: d-inline;
    margin: 0 auto;
    background-color: #ffb223;
    font-weight: 600;
    font-family: "SilkaSemibold";
    color: #09091a;
  }
}

.comoLoHacemos {
  &-title {
    font-size: 23px;
    text-align: center;
    @media (max-width: 767.98px) {
      padding-top: 1rem;
      color: #fff;
      -webkit-text-stroke: 0px !important;
    }

    @media (min-width: 768px) {
      text-align: left;
      font-size: 60px;
    }
  }
  &-tabs {
    flex-direction: column;
    a.nav-link {
      font-family: "SilkaRegular";
      opacity: 0.5;
      font-size: 40px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.39;
      letter-spacing: normal;
      color: #fff !important;
      margin-bottom: 1.4rem;

      &.active {
        opacity: 1;
      }
    }
  }

  &-tabContent {
    &-side {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      &-item {
        p {
          font-family: "SilkaMedium";
          font-size: 22px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.55;
          letter-spacing: normal;
          text-align: center;
          color: #fff;
          margin-top: 1rem;
        }
        margin-bottom: 1.7rem;
      }
    }
  }
}

.accordionMarketplace {
  .accordion-button {
    width: 100%;
    font-size: 23px;
    font-family: "SilkaMedium";
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: #fff;
    text-align: left;
    background-color: transparent;
    opacity: 0.2;
    &:not(.collapsed) {
      opacity: 1;
    }
  }
  .accordion-body {
    font-family: "SilkaMedium";
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2.13;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: flex-start;
      li {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
