.our-services {
    content-visibility: auto;
  .service-block-wrapper {
    position: relative;
    @media (min-width: 992px) {
      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 140px;
        height: 140px;
        background-color: $color--red;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: translateX(50%);
        z-index: 2;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 120px;
        height: 120px;
        background-image: url("/img/services-square-deco.jpg");
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        transform: translateX(50%);
        z-index: 3;
      }
    }
  }
  .service-block {
    background-color: $color--lightpurple;
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url("/img/bg-services.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0;
      mix-blend-mode: multiply;
      transition: all 0.3s ease-in-out;
    }
    &:hover {
      &:before {
        opacity: 0.3;
      }
    }
  }
  .services-body {
    position: relative;
    background-color: #fff;
    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-image: url("/img/bg-services-2.jpg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      opacity: 0.05;
    }
    @media (min-width: 768px) {
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 45%;
        height: 9px;
        background-color: $color--red;
        right: 0;
        bottom: 40px;
      }
    }
  }
}
