.whatsapp__link {
	position: fixed;
	width: 60px;
	height: 60px;
	left: 25px;
	bottom: 25px;
	z-index: 5;
	img {
		width: 100%;
		height: 100%;
	}
}
